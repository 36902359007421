import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, tap, retryWhen, delayWhen } from 'rxjs/operators';
import { ResultModel } from '../../models/result-model';
import { environment } from '../../../environments/environment';

import { LocalStorageHelper } from '../helpers/local-storage.helper';

import { AccessTokenModel } from '../../models/access-token.model';

import { AuthService } from './auth.service';
import { SnackBarHelper } from '../helpers/snack-bar.helper';
import { Options } from 'selenium-webdriver/safari';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';

@Injectable({
	providedIn: 'root',
})
export class MakeRequestService {
	readonly authHeaderName = 'authentication';
	readonly retryInterval = 3000;

	constructor(
		private http: HttpClient,
		private localStorageHelper: LocalStorageHelper,
		private router: Router,
		private injector: Injector,
		public snackBarHelper: SnackBarHelper,
		private translate: TranslateService,
	) {
		this.translate.setTranslation('en', english, true);
		this.translate.setTranslation('da', danish, true);
	}
	getTranslation(key: string): string {
		if (this.translate.currentLang === 'en') {
			return english.data[key];
		}
		return danish.data[key];
	}

	public getWithoutRetries<T>(
		url: string,
		headers?: HttpHeaders,
		errorCallback?: () => void
	): Observable<ResultModel<T>> {
		headers = this.addAuthHeader(headers);
		url = environment.baseUrl + url;
		return this.http.get<T>(url, { headers }).pipe(
			map(x => new ResultModel<T>(x)),
			catchError(this.handleError<any>(url, null, errorCallback)),
		);
	}

	public get<T>(
		url: string,
		headers?: HttpHeaders,
		errorCallback?: () => void
	): Observable<ResultModel<T>> {
		headers = this.addAuthHeader(headers);
		url = environment.baseUrl + url;
		return this.http.get<T>(url, { headers }).pipe(
			map(x => new ResultModel<T>(x)),
			retryWhen(errors => {
				return errors.pipe(
					tap((error) => this.handleError<any>(url, null,errorCallback)(error)),
					delayWhen((error) => {
						if (error.status >= 400 || error.status < 500) {
							return new Observable<never>();
						}
						return timer(this.retryInterval);
					}),
				)
			}),
		);
	}
	getBlob(
		url: string,
		headers?: HttpHeaders,
	): Observable<Blob> {
		headers = this.addAuthHeader(headers);
		url = environment.baseUrl + url;
		return this.http.get(url, { headers: headers, responseType: 'blob', reportProgress: true });
	}

	public post<T>(
		url: string,
		data: any,
		headers?: HttpHeaders,
	): Observable<ResultModel<T>> {
		headers = this.addAuthHeader(headers);
		url = environment.baseUrl + url;
		return this.http.post<T>(url, data, { headers }).pipe(
			map(x => new ResultModel<T>(x)),
			catchError(this.handleError<any>(url, null)),
		);
	}

	public put<T>(
		url: string,
		data: any,
		headers?: HttpHeaders,
	): Observable<ResultModel<T>> {
		headers = this.addAuthHeader(headers);
		url = environment.baseUrl + url;
		return this.http.put<T>(url, data, { headers }).pipe(
			map(x => new ResultModel<T>(x)),
			catchError(this.handleError<any>(url, null)),
		);
	}

	public patch<T>(
		url: string,
		data: any,
		headers?: HttpHeaders,
	): Observable<ResultModel<T>> {
		headers = this.addAuthHeader(headers);
		url = environment.baseUrl + url;
		return this.http.patch<T>(url, data, { headers }).pipe(
			map(x => new ResultModel<T>(x)),
			catchError(this.handleError<any>(url, null)),
		);
	}

	public delete<T>(
		url: string,
		headers?: HttpHeaders,
	): Observable<ResultModel<T>> {
		headers = this.addAuthHeader(headers);
		url = environment.baseUrl + url;
		return this.http.delete<T>(url, { headers }).pipe(
			map(x => new ResultModel<T>(x)),
			catchError(this.handleError<T>(url, null)),
		);
	}

	private handleError<T>(
		url = '/',
		result?: T,
		errorCallback?: () => void
	): (error: any) => Observable<ResultModel<T>> {
		return (error: any): Observable<ResultModel<T>> => {
			switch (error.status) {
				case 0:
					this.handleUnableToConnectError();
					break;
				case 200:
					break;
				case 400:
					this.handle400(error.error);
					break;
				case 401:
					this.handle401();
					break;
				case 403:
					this.handle403();
					break;
				default:
					this.handleUnknownError();
					break;
			}
			if (errorCallback) {
				errorCallback();
			}
			const resultModel = new ResultModel<T>(result, false, error.status);
			if (error.error) {
				resultModel.errors = error.error.errors;
			}
			resultModel.error = error.error;
			return of(resultModel);
		};
	}

	private addAuthHeader(headers: HttpHeaders): HttpHeaders {
		const token = this.localStorageHelper.Get<AccessTokenModel>(
			LocalStorageHelper.accessTokenFieldName,
		);
		headers = headers || new HttpHeaders();
		if (token.token) {
			headers = headers.append(this.authHeaderName, token.token);
		}
		return headers;
	}

	// custom error handler for each response code.
	private handleUnableToConnectError(): void {
		this.snackBarHelper.openSnackBar({
			message: this.getTranslation('UnableToConnect'),
		});
	}

	private handleUnknownError(): void {
		this.snackBarHelper.openSnackBar({
			message: this.getTranslation('SomethingWentWrong'),
		});
	}

	private handle400(error: any): void {
		console.log(error);
	}

	private handle401(): void {
		if (this.router.url === '/auth/login') {
			return;
		}
		const authService = this.injector.get(AuthService);
		authService.Logout();
	}

	private handle403(): void {
		this.snackBarHelper.openSnackBar({
			message: this.getTranslation('UnauthorizedAccess'),
		});
		this.router.navigate(['/']);
	}
}
