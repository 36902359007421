import {
	Component,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
	Input,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { Navigation } from 'app/navigation/navigation';
import { AuthService } from 'app/services/app/auth.service';
import { User } from 'app/models/user.model';
import { LocalStorageHelper } from 'app/services/helpers/local-storage.helper';
import { LanguageService } from 'app/services/language.service';

@Component({
	selector: 'toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
	horizontalNavbar: boolean;
	rightNavbar: boolean;
	hiddenNavbar: boolean;
	languages: any;
	navigation: any;
	selectedLanguage: any;
	userStatusOptions: any[];
	currentUser: User;
	// Private
	private _unsubscribeAll: Subject<any>;

	constructor(
		private localStorageHelper: LocalStorageHelper,
		private _fuseConfigService: FuseConfigService,
		private _fuseSidebarService: FuseSidebarService,
		private _translateService: TranslateService,
		private languageService: LanguageService,
	) {
		// Set the defaults
		this.userStatusOptions = [
			{
				title: 'Online',
				icon: 'icon-checkbox-marked-circle',
				color: '#4CAF50',
			},
			{
				title: 'Away',
				icon: 'icon-clock',
				color: '#FFC107',
			},
			{
				title: 'Do not Disturb',
				icon: 'icon-minus-circle',
				color: '#F44336',
			},
			{
				title: 'Invisible',
				icon: 'icon-checkbox-blank-circle-outline',
				color: '#BDBDBD',
			},
			{
				title: 'Offline',
				icon: 'icon-checkbox-blank-circle-outline',
				color: '#616161',
			},
		];

		this.languages = [
			{
				id: 'en',
				title: 'English',
				flag: 'us',
			},
			{
				id: 'da',
				title: 'Danish',
				flag: 'da',
			},
		];

		this.navigation = Navigation.GetAllowedItems();

		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void {
		// Subscribe to the config changes
		this._fuseConfigService.config
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(settings => {
				this.horizontalNavbar =
					settings.layout.navbar.position === 'top';
				this.rightNavbar = settings.layout.navbar.position === 'right';
				this.hiddenNavbar = settings.layout.navbar.hidden === true;
			});

		// Set the selected language from default languages
		this.selectedLanguage = _.find(this.languages, {
			id: this._translateService.currentLang,
		});
		setTimeout(() => {
			this.selectedLanguage = _.find(this.languages, {
				id: this._translateService.currentLang,
			});
         });
	

		this.currentUser = this.localStorageHelper.Get<User>(
			LocalStorageHelper.currentUserFieldName,
		);
	}

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	toggleSidebarOpen(key): void {
		this._fuseSidebarService.getSidebar(key).toggleOpen();
	}

	search(value: any): void {
		// Do your search here...
		// console.log(value);
	}

	setLanguage(lang: { id: string }): void {
		// Set the selected language for the toolbar
		this.selectedLanguage = lang;
		this.languageService.setLanguage(lang);
	}
}
