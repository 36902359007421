import { FuseNavigation } from '@fuse/types';
import { LocalStorageHelper } from 'app/services/helpers/local-storage.helper';
import { Injector } from '@angular/core';
import { AccessTokenModel } from 'app/models/access-token.model';

export class Navigation {
	static injector: Injector;
	static roles = [
		{ name: 'SuperAdmin', level: 1 },
		{ name: 'Admin', level: 2 },
		{ name: 'User', level: 3 },
		{ name: 'None', level: Number.MAX_SAFE_INTEGER },
	];
	static GetAllowedItems(): FuseNavigation[] {
		const localStorageHelper: LocalStorageHelper = this.injector.get(
			LocalStorageHelper,
		);
		const role: string =
			localStorageHelper.Get<AccessTokenModel>(
				LocalStorageHelper.accessTokenFieldName,
			).type || '';
		const arr: FuseNavigation[] = [];
		navigation.forEach(element => {
			const item = Navigation.FilterItem(element, role);
			if (item !== null) {
				arr.push(item);
			}
		});
		return arr;
	}
	static FilterItem(
		item: FuseNavigationWithRole,
		role: string,
	): FuseNavigation {
		// const fuseItem: FuseNavigation = null;
		const fuseItem: FuseNavigation = {} as FuseNavigation;
		if (Navigation.CompareRoles(item.Role, role)) {
			Object.assign(fuseItem, item);
			if (item.children && item.children.length) {
				fuseItem.children = item.children
					.map(obj => Navigation.FilterItem(obj, role))
					.filter(obj => obj != null);
			}
		}
		return fuseItem;
	}
	static CompareRoles(itemRole: string, userRole: string): boolean {
		userRole = userRole || 'None';
		const itemLevel = Navigation.roles.find(r => r.name === itemRole).level;
		const userLevel = Navigation.roles.find(r => r.name === userRole).level;
		return userLevel <= itemLevel;
	}
}

interface FuseNavigationWithRole extends FuseNavigation {
	Role: string;
	children?: FuseNavigationWithRole[];
}

const navigation: FuseNavigationWithRole[] = [

	{
		id: 'applications',
		title: 'Applications',
		translate: 'NAV.APPLICATIONS',
		type: 'group',
		Role: 'None',
		children: [
			{
				id: 'dashboard',
				title: 'Dashboards',
				translate: 'NAV.Dashboard',
				type: 'item',
				icon: 'dashboard',
				url: '/dashboard',
				Role: 'User',
			}
			,
			{
				id: 'user',
				title: 'User',
				translate: 'NAV.USER.TITLE',
				type: 'item',
				icon: 'person',
				url: '/user',
				Role: 'SuperAdmin',
			},
			{
				id: 'Company',
				title: 'Company',
				translate: 'NAV.COMPANY.TITLE',
				type: 'collapsable',
				Role: 'SuperAdmin',
				icon: 'business',
				children: [
					{
						id: 'My company',
						title: 'My company',
						translate: 'NAV.COMPANY.MyCOMPANY.TITLE',
						type: 'item',
						// icon: 'business',
						url: '/company',
						Role: 'SuperAdmin',
					},
					{
						id: 'companies',
						title: 'Companies',
						translate: 'NAV.COMPANY.COMPANIES.TITLE',
						type: 'item',
						// icon: 'business',
						url: '/companies',
						Role: 'SuperAdmin',
					},
				],
			},
		],
	},
	{
		id: 'forms',
		title: 'forms',
		translate: 'NAV.FORMS',
		type: 'group',
		Role: 'User',
		children: [
			{
				id: 'forms',
				title: 'forms',
				translate: 'NAV.FORMS',
				type: 'item',
				icon: 'apps',
				url: '/forms',
				Role: 'User',
			},
		],
	},
	{
		id: 'orders',
		title: 'orders',
		translate: 'NAV.ORDERS',
		type: 'group',
		Role: 'User',
		children: [
			{
				id: 'orders',
				title: 'orders',
				translate: 'NAV.ORDERS',
				type: 'item',
				icon: 'shopping_cart',
				url: '/orders',
				Role: 'User',
			},
		],
	},
	{
		id: 'logout',
		title: 'logout',
		translate: 'NAV.LOGOUT',
		type: 'group',
		Role: 'User',
		children: [
			{
				id: 'logout',
				title: 'logout',
				translate: 'NAV.LOGOUT',
				type: 'item',
				icon: 'person',
				url: '/auth/logout',
				Role: 'User',
			},
		],
	},
];
