export const locale = {
	lang: 'en',
	data: {
		'SAMPLE': {
			'HELLO': 'Hello, World!'
		},
		'Company': {
			'Company': 'Company',
		},
		'MyCompany': {
			'EditCompanyInfo': 'Edit company info',
			'Name': 'Name',
			'NameRequired': 'Name is required',
			'NameTaken': 'Name taken by another company',
			'Website': 'Website',
			'WebsiteRequired': 'Website is required',
			'Phone': 'Phone',
			'PhoneRequired': 'Phone is required',
			'Email': 'Email',
			'EmailTaken': 'Email taken by another company',
			'ApiKey': 'Api Key',
			'ApiKeyInvalid': 'api-key not valid',
			'ApiKeyRequired': 'api-key is required',
			'Language': 'Default Language',
			'English': 'English',
			'Danish': 'Danish',
			'Address': 'Address',
			'AddressRequired': 'Address is required',
			'SaveChanges': 'Save changes',
		},
		'List': {
			'AddCompany': 'Add new company',
			'Name': 'Name',
			'Website': 'Website',
			'Email': 'Email',
			'Phone': 'Phone',
			'Actions': 'Actions',
			'ConfirmDelete': 'Are you sure, you want to delete',
			'ThatHaveAPIKey': 'that have API key',
			'WithAllRelatedData': 'with all related data'
		},
		'Companies': {
			'Companies': 'Companies',
		},
		'Paginator': {
			'ItemsPerPage': 'Items per page',
		},
		'TSvariables': {
			'Company': 'Company',
			'AddNewCompany': 'Add new Company',
			'EditCompany': 'Edit Company',
			'Info': 'Info',
			'EditedSuccessfully': 'Edited Successfully',
			'SomethingWentWrong': 'Something wen wrong, please try again',
			'AddedSuccessfully': 'Added Successfully',
		}
	}
};
