import {
	Router,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { MakeRequestService } from './app/make-request.service';
import { ResultModel } from '../models/result-model';
import FormDefinition from 'app/models/form-builder/form-definition.model';
import { Credentials } from 'app/models/form-builder/credentials';
import { AuthService } from './app/auth.service';
import { CompanyService } from './company.service';
import { tap, take } from 'rxjs/operators';
import SearchParameters from 'app/models/search-parameters.model';
import PaginatedModel from 'app/models/paginated-order.model';

@Injectable({
	providedIn: 'root',
})
export class FormService {
	searchParameters: SearchParameters = new SearchParameters();
	baseModuleUrl = 'Form';
	onFormsChanged: BehaviorSubject<FormDefinition[]>;
	routeParams: any;
	form: any;
	onFormChanged: BehaviorSubject<FormDefinition> | any;
	searchParametersChanged: Subject<SearchParameters> = new Subject<SearchParameters>();
	public forms: FormDefinition[] = [];
	public totalFormsCount: number = 0;
	public dataIsLoading: Subject<boolean> = new Subject<boolean>();
	
	constructor(
		private makeRequest: MakeRequestService,
		private authService: AuthService,
		private companyService: CompanyService,
	) {
		this.onFormsChanged = new BehaviorSubject([]);
		this.onFormChanged = new BehaviorSubject(new FormDefinition());

		this.searchParametersChanged.subscribe((searchParameters: SearchParameters) => {
			this.searchParameters = searchParameters;
			this.dataIsLoading.next(true);
			this.getAll(this.searchParameters).subscribe((response: any) => {
				this.forms = response.Result.list;
				this.totalFormsCount = response.Result.total;
				this.onFormsChanged.next(this.forms);
				this.dataIsLoading.next(false);
			});
		});
	}

	public get(id: string): Observable<ResultModel<FormDefinition>> {
		return this.makeRequest.get<FormDefinition>(
			`${this.baseModuleUrl}/${id}`,
		);
	}

	public getAll(SearchParameters: SearchParameters): Observable<ResultModel<PaginatedModel<FormDefinition>>> {
		const { page, size, searchValue, sortValue, sortAscending } = SearchParameters;
		if (this.authService.isCurrentUserSuperAdmin()) {
			const currentOperatedCompamyId = this.companyService.currentOperatedCompany?.id;
			return this.makeRequest.getWithoutRetries<PaginatedModel<FormDefinition>>(
					`${this.baseModuleUrl}?companyId=${currentOperatedCompamyId}&Page=${page}&Size=${size}&SearchValue=${searchValue}&SortValue=${sortValue}&SortAscending=${sortAscending}`,
					null,
					() => this.dataIsLoading.next(false)
				)
				.pipe(take(1),
					tap(response => {
						this.forms = response.Result.list;
						this.onFormsChanged.next(this.forms);
					})
				);
		}
		
		return this.makeRequest.get<PaginatedModel<FormDefinition>>(
				`${this.baseModuleUrl}?Page=${page}&Size=${size}&SearchValue=${searchValue}&SortValue=${sortValue}&SortAscending=${sortAscending}`,
				null,
				() => this.dataIsLoading.next(false)
			)
			.pipe(take(1),
				tap(response => {
					this.forms = response.Result.list;
					this.onFormsChanged.next(this.forms);
				})
			);
	}

	public Update(
		form: FormDefinition,
	): Observable<ResultModel<FormDefinition>> {
		return this.makeRequest.put<FormDefinition>(
			`${this.baseModuleUrl}/${form.id}`,
			form,
		);
	}

	public PatchUpdate(formId: string, formJsonPatch: any): Observable<ResultModel<FormDefinition>> {
		return this.makeRequest.patch<any>(`${this.baseModuleUrl}/${formId}`, formJsonPatch);
	}

	public Add(form: FormDefinition): Observable<ResultModel<FormDefinition>> {
		return this.makeRequest.post<FormDefinition>(
			`${this.baseModuleUrl}`,
			form,
		);
	}
	
	public Publish(formId: string, isPublished: boolean): Observable<ResultModel<FormDefinition>> {
		return this.makeRequest.post<FormDefinition>(
			`${this.baseModuleUrl}/publish/${formId}?isPublished=${isPublished}`,
			null,
		);
	}

	public Duplicate(formId: string): Observable<ResultModel<FormDefinition>> {
		return this.makeRequest.post<FormDefinition>(
			`${this.baseModuleUrl}/duplicate/${formId}`,
			formId
		);
	}

	public Delete(id: string): Observable<ResultModel<FormDefinition>> {
		return this.makeRequest.delete<FormDefinition>(
			`${this.baseModuleUrl}/${id}`,
		);
	}

	public resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;

		return new Promise((resolve, reject) => {
			if (this.routeParams.id === 'new') {
				this.onFormChanged.next(false);
				resolve(false);
			} else if (this.routeParams.id) {
				this.get(this.routeParams.id).subscribe((response: any) => {
					this.form = response.Result;
					this.onFormChanged.next(this.form);
					resolve(response);
				}, reject);
			} else {
				this.dataIsLoading.next(true);
				this.getAll(this.searchParameters).subscribe((response: any) => {
					this.forms = response.Result.list;
					this.totalFormsCount = response.Result.total;
					this.onFormsChanged.next(this.forms);
					this.dataIsLoading.next(false);
					resolve(response);
				}, reject);
			}
		});
	}

	public saveForm(form): Promise<any> {
		return new Promise((resolve, reject) => {
			this.Update(form).subscribe((response: any) => {
				resolve(response);
			}, reject);
		});
	}

	public addForm(form): Promise<any> {
		return new Promise((resolve, reject) => {
			this.Add(form).subscribe((response: any) => {
				resolve(response);
			}, reject);
		});
	}

	public Validate(form: Credentials): Observable<ResultModel<Credentials>> {
		return this.makeRequest.post<Credentials>(
			`${this.baseModuleUrl}/ValidateESignatureCredentials`,
			form,
		);
	}
}
