import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MakeRequestService } from './app/make-request.service';
import { User } from '../models/user.model';
import { ActivationModel } from '../models/activation.model';
import { ResultModel } from '../models/result-model';
import { LoginResponseModel } from '../models/login-response.model';
import { UserProfile } from 'app/models/user-profile.model';
import SearchParameters from 'app/models/search-parameters.model';
import PaginatedModel from 'app/models/paginated-order.model';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	baseModuleUrl = 'User/';

	constructor(
		private makeRequest: MakeRequestService,
		private router: Router,
	) {}

	public get(id: string): Observable<ResultModel<User>> {
		return this.makeRequest.get<User>(`${this.baseModuleUrl}${id}`);
	}

	public getAll(searchParameters: SearchParameters): Observable<ResultModel<PaginatedModel<User>>> {
		const { page, size, searchValue, sortValue, sortAscending } = searchParameters;
		return this.makeRequest.get<PaginatedModel<User>>(
			`${this.baseModuleUrl}?page=${page}&size=${size}&searchValue=${searchValue}&sortValue=${sortValue}&sortAscending=${sortAscending}`
		);
	}

	public Update(user: User): Observable<ResultModel<User>> {
		return this.makeRequest.put<User>(
			`${this.baseModuleUrl}${user.id}`,
			user,
		);
	}
	public Validate(user: User): Observable<ResultModel<User>> {
		return this.makeRequest.post<User>(
			`${this.baseModuleUrl}Validate`,
			user,
		);
	}

	public Add(user: User): Observable<ResultModel<User>> {
		return this.makeRequest.post<User>(`${this.baseModuleUrl}`, user);
	}

	public Delete(id: string): Observable<ResultModel<User>> {
		return this.makeRequest.delete<User>(`${this.baseModuleUrl}${id}`);
	}
}
