import {
	Component,
	ElementRef,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';
import { takeUntil } from 'rxjs/operators';
import { FormService } from 'app/services/form.service';
import { FormDefinition } from 'app/models/form-builder/form-definition-types-module';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/shared-modules/mat-shared/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import FormsUtilities from 'app/utilities/Forms/forms-utilities';
import { CompanyService } from 'app/services/company.service';
import { Company } from 'app/models/company.model';
import { AuthService } from 'app/services/app/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';

@Component({
	selector: 'forms-list',
	templateUrl: './forms.component.html',
	styleUrls: ['./forms.component.scss'],
	animations: fuseAnimations,
	encapsulation: ViewEncapsulation.None,
})
export class FormsComponent implements OnInit {
	dataSource: FormsDataSource | null;
	displayedColumns = ['image', 'name', 'creator', 'createdAt', 'link', 'publish', 'actions'];

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	@ViewChild('filter', { static: true })
	filter: ElementRef;

	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	@ViewChild('fileInput', { static: true })
	fileInput: ElementRef;

	// Private
	private _unsubscribeAll: Subject<any>;

	public companiesLoaded: boolean;
	public formsLoading: boolean;
	public isSuperAdmin: boolean;
	public companies: Company[];
	public currentOperatedCompany: Company;

	constructor(
		private formsService: FormService,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
		private translate: TranslateService,
		public dialog: MatDialog,
		private authService: AuthService,
		private companyService: CompanyService,
		private _matSnackBar: MatSnackBar,
		private router: Router
	) {
		// Set the private defaults
		this._unsubscribeAll = new Subject();
		this._fuseTranslationLoaderService.loadTranslations(english, danish);
	}
	ngOnInit(): void {
		this.isSuperAdmin = this.authService.isCurrentUserSuperAdmin();
		if (this.isSuperAdmin) {
			this.initCompanies();
		}

		this.formsService.dataIsLoading.subscribe(isLoading => {
			this.formsLoading = isLoading;
		});

		this.dataSource = new FormsDataSource(
			this.formsService,
			this.sort,
			this.paginator
		);

		fromEvent(this.filter.nativeElement, 'keyup')
			.pipe(
				takeUntil(this._unsubscribeAll),
				debounceTime(150),
				distinctUntilChanged(),
			)
			.subscribe(() => {
				if (!this.dataSource) {
					return;
				}
				this.formsService.searchParameters.page = 1;
				this.paginator.pageIndex = 0;
				this.formsService.searchParameters.searchValue = this.filter.nativeElement.value;
				this.formsService.searchParametersChanged.next(this.formsService.searchParameters);
			});
	}

	initCompanies(): void {
		this.currentOperatedCompany = this.companyService.currentOperatedCompany;

		this.companyService.getAll()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(res => {
				this.companies = res.Result;
				const currentOperatedCompanyExists = this.companies.findIndex(
					company => company.id === this.currentOperatedCompany.id
				) !== -1;
				if (!currentOperatedCompanyExists) {
					this.currentOperatedCompany = this.authService.getCurrentUserCompany();
				}
				this.companiesLoaded = true;
			});
	}

	getFormUrl(formDefinition: FormDefinition): string {
		return FormsUtilities.getFormUrl(formDefinition);
	}

	getEncodedFormName(form: FormDefinition): string {
		return `/forms/${form.id}/${encodeURIComponent(form.name)}`;
	}

	refresh(): void {
		this.formsService.searchParameters.searchValue = this.filter.nativeElement.value;
		this.formsService.searchParametersChanged.next(this.formsService.searchParameters);
		this.dataSource.recordChange$.next();
	}

	Delete(form: FormDefinition): void {
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: {
				confirmMessage: `${this.translate.instant('TSvariables.ConfirmDelete')} ${form.name}?`,
			},
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.formsService.Delete(form.id).subscribe(res => {
					if (res.Success) {
						this.dataSource.removeForm(form);
						this.refresh();
					}
				});
			}
		});
	}

	Export(formId: string): void {
		this.formsService.get(formId).subscribe(res => {
			if (res.Success) {
				const form = res.Result;
				FormsUtilities.createCleanFormForExportAndImport(form);
				FormsUtilities.downloadForm(form);
			} else {
				this.openMatSnackBar(
					this.translate.instant('TSvariables.AnErrorOccurred'), 
					this.translate.instant('TSvariables.OK')
				);
			}
		});
	}

	Import(): void {
		this.fileInput.nativeElement.click();
	}

	onFileSelected(event): void {
		this.formsLoading = true;
		const file = event.target.files[0];
		const reader = new FileReader();
		reader.readAsText(file);
		reader.onload = () => {
			if (file.type.includes('json')) {
				this.importAndNavigateToForm(reader.result as string);
			} else {
				this.openMatSnackBar(
					this.translate.instant('TSvariables.InvalidFileFormat'), 
					this.translate.instant('TSvariables.OK')
				);
			}
			this.formsLoading = false;
			this.fileInput.nativeElement.value = '';
		};
	}

	private importAndNavigateToForm(jsonString: string): void {
		try {
			const formDefinition = JSON.parse(jsonString) as FormDefinition;
			if (FormsUtilities.validateImportedForm(formDefinition)){
				FormsUtilities.createCleanFormForExportAndImport(formDefinition);
				localStorage.setItem('importForm', JSON.stringify(formDefinition));
				this.router.navigate(['forms', 'new']);
			} else {
				this.openMatSnackBar(
					this.translate.instant('TSvariables.InvalidJsonFileFormat'), 
					this.translate.instant('TSvariables.OK')
				);
			}
		} catch (error) {
			this.openMatSnackBar(
				this.translate.instant('TSvariables.InvalidJsonFileFormat'), 
				this.translate.instant('TSvariables.OK')
			);
		}
	}

	Publish(formId: string, isFormPublished: boolean): void {
		let newPublishedFormState = !isFormPublished;
		this.formsService.Publish(formId, newPublishedFormState).subscribe(res => {
			if (res.Success) {
				this.openMatSnackBar(
					this.translate.instant(newPublishedFormState ? 'TSvariables.FormPublished' : 'TSvariables.FormUnpublished'), 
					this.translate.instant('TSvariables.OK')
				);
			} else {
				this.openMatSnackBar(
					this.translate.instant('TSvariables.AnErrorOccurred'), 
					this.translate.instant('TSvariables.OK')
				);
				newPublishedFormState = !newPublishedFormState;
				this.refresh();
			}	
				
			this.dataSource.updateForm(formId, newPublishedFormState);
		});
	}

	private openMatSnackBar(message: string, action: string): void {
		this._matSnackBar.open(message, action, {
			verticalPosition: 'top',
			duration: 2000,
		});
	}

	Duplicate(formId: string): void {
		this.formsService.Duplicate(formId).subscribe(res => {
			if (res.Success) {
				const index = this.dataSource.getFormIndex(formId);
				this.dataSource.addForm(res.Result, index + 1);
				this.refresh();
			} else {
				this.openMatSnackBar(this.translate.instant('TSvariables.AnErrorOccurred'), this.translate.instant('TSvariables.OK'));
			}
		});
	}

	selectedCompanyChange(companyId: string): void {
		debugger;
		const filteredCompanies = this.companies.filter(company => company.id === companyId);
		if (!filteredCompanies || !filteredCompanies[0]) {
			return;
		}
		const newlySelectedCompany = filteredCompanies[0];
		this.companyService.currentOperatedCompany = newlySelectedCompany;
		this.currentOperatedCompany = newlySelectedCompany;
		this.formsService.searchParameters.page = 1;
		this.formsService.searchParametersChanged.next(this.formsService.searchParameters);
	}
}

export class FormsDataSource extends DataSource<any> {
	private _filterChange = new BehaviorSubject('');
	private _filteredDataChange = new BehaviorSubject('');
	public totalDataCount: number = 0;
	//https://stackoverflow.com/questions/46746598/angular-material-how-to-refresh-a-data-source-mat-table
	public recordChange$ = new Subject();

	constructor(
		private formsService: FormService,
		private _matSort: MatSort,
		private _matPaginator: MatPaginator,
	) {
		super();
		this.filteredData = this.formsService.forms;
		this.totalDataCount = this.formsService.totalFormsCount;
		this._matPaginator.pageIndex = this.formsService.searchParameters.page - 1;
		this._matPaginator.pageSize = this.formsService.searchParameters.size;
		this.formsService.searchParametersChanged.next(this.formsService.searchParameters);
	}
	connect(): Observable<any[]> {
		const displayDataChanges = [
			this.formsService.onFormsChanged,
			this._filterChange,
			this._matSort.sortChange,
			this.recordChange$,
		];

		this._matPaginator.page.subscribe(() => {
			this.formsService.searchParameters.page = this._matPaginator.pageIndex + 1;
			this.formsService.searchParameters.size = this._matPaginator.pageSize;
			this.formsService.searchParametersChanged.next(this.formsService.searchParameters);
		});

		this._matSort.sortChange.subscribe(() => {
			this.formsService.searchParameters.sortValue = this._matSort.active.charAt(0).toUpperCase() + this._matSort.active.slice(1);
			this.formsService.searchParameters.sortAscending = this._matSort.direction === 'asc';
			this.formsService.searchParametersChanged.next(this.formsService.searchParameters);
		});

		return merge(...displayDataChanges).pipe(
			map(() => {
				this.filteredData = this.formsService.forms;
				this.totalDataCount = this.formsService.totalFormsCount;
				return this.filteredData;
			}),
		);
	}

	removeForm(form: FormDefinition): void {
		this.formsService.forms = this.formsService.forms.filter(f => f !== form);
	}

	addForm(form: FormDefinition, index: number): void {
		this.formsService.forms.splice(index, 0, form);
	}

	updateForm(formId: string, newPublishedFormState: boolean): void {
		const index = this.getFormIndex(formId);
		this.formsService.forms[index].published = newPublishedFormState;
	}

	getFormIndex(formId: string): number {
		return this.formsService.forms.findIndex(i => i.id == formId);
	}

	get filteredData(): any {
		return this._filteredDataChange.value;
	}

	set filteredData(value: any) {
		this._filteredDataChange.next(value);
	}

	// Filter
	get filter(): string {
		return this._filterChange.value;
	}

	set filter(filter: string) {
		this._filterChange.next(filter);
	}
	filterData(data): any {
		if (!this.filter) {
			return data;
		}
		return FuseUtils.filterArrayByString(data, this.filter);
	}
	sortData(data): any[] {
		if (!this._matSort.active || this._matSort.direction === '') {
			return data;
		}

		return data.sort((a, b) => {
			let propertyA: number | string = '';
			let propertyB: number | string = '';

			switch (this._matSort.active) {
				case 'id':
					[propertyA, propertyB] = [a.id, b.id];
					break;
				case 'name':
					[propertyA, propertyB] = [a.name, b.name];
					break;
				/*case 'active':
					[propertyA, propertyB] = [a.active, b.active];
					break;*/
			}

			const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
			const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

			return (
				(valueA < valueB ? -1 : 1) *
				(this._matSort.direction === 'asc' ? 1 : -1)
			);
		});
	}

	disconnect(): void { }
}
