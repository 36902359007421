export const locale = {
	lang: 'en',
	data: {
		'Forms': {
			'Forms': 'Forms',
			'SearchForForm': 'Search for a form',
			'AddNewForm': 'ADD NEW FORM',
			'ImportForm': 'IMPORT FORM',
			'Name': 'Name',
			'Link': 'Link',
			'GoToForm': 'Go to form',
			'Active': 'Active',
			'Creator': 'Creator',
			'CreatedAt': 'Created at',
			'Actions': 'Actions',
			'Company': 'Company',
			'Published': 'Published',
		},
		'Paginator': {
			'ItemsPerPage': 'Items per page',
		},
		'Form': {
			'NewForm': 'New Form',
			'Save': 'Save',
			'FormDetail': 'Form Detail',
			'Add': 'ADD',
			'BasicInformation': 'Basic Information',
			'FormName': 'Form Name',
			'DocumentName': 'Document Name',
			'FormLink': 'Form link',
			'CreatorId': 'User ID',
			'CreatorIdInvalid': 'Invalid user ID',
			'CompanyUrl': 'Company URL',
			'CompanyUrlInvalid': 'Invalid company URL',
			'SenderEmail': 'Sender email',
			'SenderEmailInvalid': 'Invalid sender email',
			'SomethingIsMissing': 'Something is missing',
			'Language': 'Language',
			'English': 'English',
			'Danish': 'Danish',
			'LanguageRequired': 'Language required',
			'NameRequired': 'Form name is required',
			'DocumentNameRequired': 'Document name is required',
			'NameTaken': 'Name taken by another form',
			'ValidateESignaturCredentials': 'Validate esignatur credentials',
			'HeaderAndFooter': 'Header & Footer',
			'FormHeaderBackgroundColor': 'Form-name background color',
			'FormHeaderTextColor': 'Form-name text color',
			'HideFormName': 'Hide form name',
			'Left': 'Left',
			'Center': 'Center',
			'Right': 'Right',
			'Header': 'Header',
			'Footer': 'Footer',
			'FormContent': 'Form content',
			'Fields': 'Fields',
			'PrimarySigner': 'Primary signer',
			'AdditionalSigner': 'Additional signer',
			'RemoveSigner': 'Remove signer',
			'EmptySigner': 'Empty signer',
			'AddSigner': 'Add signer',
			'FormFields': {
				'address': 'Address',
				'text': 'Text',
				'email': 'Email',
				'date': 'Date',
				'number': 'Number',
				'tel': 'Tel',
				'radio': 'Radio',
				'checkbox': 'Checkbox',
				'select': 'Drop-down',
				'textarea': 'Textarea',
				'customRegEx': 'CustomRegEx',
				'file': 'File',
				'cpr': 'CPR',
				'cvr': 'CVR',
				'bank': 'Bank',
				'identification': 'Identification',
				'typeAhead': 'Typeahead'
			},
			'AdvancedFields': 'Advanced Fields',
			'Grouptitle': 'Group title',
			'DependentOn': 'Dependent On',
			'NotDependent': 'not dependent',
			'ChooseAValue': 'Choose a value',
			'SignerInformation': 'Signer Information',
			'Title': 'Title',
			'OnBehalfOf': 'On Behalf Of',
			'NotMapped': 'not mapped',
			'Preview': 'Preview',
			'SignatureType': 'Signature Type',
			'EasySign': 'EasySign',
			'MitId': 'MitId',
			'SignatureTypeRequired': 'Signature Type Required',
			'Notifications': 'Notifications',
			'NotifiedEmails': 'Emails without attachments',
			'NotifiedAttachmentEmails': 'Emails with attachments',
			'AddNotifiedEmailTooltip': 'Type an email and press enter',
			'AddNotifiedEmailError': 'Enter a valid email',
			'AttachDocumentToEmail': 'Attach document to email',
			'SendCompletedEmailToSigners': 'Send completed email to signer(s)',
			'AttachDocumentToSignerEmail': 'Attach document to signer email',
			'AttachmentsExceedingThreshold': 'Please note that if the form has one or more file upload field, and the size of the uploaded files is too large, it will not be sent with the email',
		},
		'EditField': {
			'Label': 'label',
			'LabelRequired': 'Label required',
			'Name': 'name',
			'Placeholder': 'placeholder',
			'NameRequired': 'Name required',
			'NameNotUnique': 'Name is not unique',
			'NoRestrictedCharacters': '/\. "$*<>:|? are not allowed',
			'Value': 'Value',
			'ValueRequired': 'Value required',
			'Options': 'options',
			'OptionsRequired': 'Options required',
			'EnableAutoComplete': 'enable autocomplete',
			'Required': 'required',
			'DependenciesMet': 'All dependencies must be met',
			'Multi': 'Multi',
			'MinValue': 'Minimum value',
			'MaxValue': 'Maximum value',
			'MaxMinError': 'Max value should be greater than min value',
			'Step': 'Step',
			'DateFormat': 'Date format',
			'DependencyList': 'Dependency list',
			'CustomValue': 'Custom Value',
			'Exact': 'Exact',
			'LessThan': 'Less than',
			'MoreThan': 'More than',
			'ChooseValue': 'Choose a value',
			'ValueNotUnique': 'Value not unique',
			'MatchedValue': 'Matched value',
			'DependentFields': 'Dependent fields',
			'Update': 'update',
			'HasValue': 'Has Value',
			'NoValue': 'No Value',
			'Delete': 'DELETE',
			'OptionsTooltip': 'Type option and press enter',
			'AddOptionWithDescriptionTooltip': 'Add option',
			'Success': 'Success',
			'Failure': 'Failure',
			'UploadViaExcel': 'Upload via Excel',
			'DownloadExcelTemplate': 'Download Excel template',
			'EditOption': {
				'AddOptionWithDescription': 'Add option',
				'EditOptionWithDescription': 'Edit option',
				'Title': 'Title',
				'Value': 'Value',
				'Description': 'Description',
				'OptionTitleRequired': 'Option title required',
				'OptionValueRequired': 'Option value required',
				'OptionDescriptionRequired': 'Option description required',
				'Save': 'Save',
				'Cancel': 'Cancel',
			}
		},
		'TSvariables': {
			'Formsaved': 'Form saved',
			'FormPublished': 'Form was published',
			'FormUnpublished': 'Form was unpublished',
			'FormExported': 'Form exported',
			'FormImported': 'Form imported',
			'ImportFormError': 'Error importing form',
			'InvalidFileFormat': 'Invalid file format',
			'InvalidJsonFileFormat': 'Invalid JSON file format',
			'AnErrorOccurred': 'An error occurred',
			'FormAdded': 'Form added',
			'ValidCredentials': 'Valid credentials',
			'OK': 'OK',
			'ConfirmDelete': 'Are you sure, you want to delete',
			'UnsavedChangesConfirmMessage': 'You have unsaved changes. Are you sure you want to leave this page?',
		}
	}
};
