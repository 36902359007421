export const locale = {
	lang: 'da',
	data: {
		'Forms': {
			'Forms': 'Blanketter',
			'SearchForForm': 'Søg efter blanket',
			'AddNewForm': 'Tilføj ny blanket',
			'ImportForm': 'Importer blanket',
			'Name': 'Navn',
			'Link': 'Link',
			'GoToForm': 'Gå til blanket',
			'Active': 'Aktiv',
			'Creator': 'Oprettet af',
			'CreatedAt': 'Oprettet kl',
			'Actions': 'Handlinger',
			'Company': 'Selskab',
			'Published': 'Publiceret',
		},
		'Paginator': {
			'ItemsPerPage': 'Blanketter pr. Side',
		},
		'Form': {
			'NewForm': 'Ny blanket',
			'Save': 'Gem',
			'FormDetail': 'Blanket detaljer',
			'Add': 'Tilføj',
			'BasicInformation': 'Grundoplysninger',
			'FormName': 'Blanket Navn',
			'DocumentName': 'Dokumentnavn',
			'FormLink': 'Blanket link',
			'CreatorId': 'BrugerID',
			'CreatorIdInvalid': 'Ugyldigt brugerID',
			'CompanyUrl': 'Selskabs-URL',
			'CompanyUrlInvalid': 'Ugyldig selskabs-URL',
			'SenderEmail': 'Afsender e-mail',
			'SenderEmailInvalid': 'Ugyldig afsender e-mail',
			'SomethingIsMissing': 'Noget mangler',
			'Language': 'Sprog',
			'English': 'Engelsk',
			'Danish': 'Dansk',
			'LanguageRequired': 'Sprog kræves',
			'NameRequired': 'Navn kræves',
			'DocumentNameRequired': 'Dokumentnavn kræves',
			'NameTaken': 'Navn ikke unikt',
			'ValidateESignaturCredentials': 'Valider esignatur kontooplysninger',
			'HeaderAndFooter': 'Sidehoved og sidefod',
			'FormHeaderBackgroundColor': 'Blanket farve',
			'FormHeaderTextColor': 'Sidehovedtekstfarve',
			'HideFormName': 'Skjul formularnavn',
			'Left': 'Venstre',
			'Center': 'Centrum',
			'Right': 'Højre',
			'Header': 'Sidehoved',
			'Footer': 'Sidefod',
			'FormContent': 'Blanket indhold',
			'Fields': 'Felter',
			'PrimarySigner': 'Primær underskriver',
			'AdditionalSigner': 'Ekstra signer',
			'RemoveSigner': 'Fjern underskriveren',
			'EmptySigner': 'Tom underskriver',
			'AddSigner': 'Tilføj underskriver',
			'FormFields': {
				'address': 'Adresse',
				'text': 'Text',
				'email': 'E-mail',
				'date': 'Dato',
				'number': 'Nummer',
				'tel': 'Telefon',
				'radio': 'Radio',
				'checkbox': 'Afkrydsning',
				'select': 'Drop-down',
				'textarea': 'Tekstområde',
				'customRegEx': 'RegEx',
				'file': 'Fil',
				'cpr': 'CPR',
				'cvr': 'CVR',
				'bank': 'Bank',
				'identification': 'Identifikation',
				'typeAhead': 'Typeahead'
			},
			'AdvancedFields': 'Advancerede felter',
			'Grouptitle': 'Navn på gruppe',
			'DependentOn': 'Afhængig af',
			'NotDependent': 'Ikke afhængig',
			'ChooseAValue': 'Vælg en værdi',
			'SignerInformation': 'Underskriver information',
			'Title': 'Titel',
			'OnBehalfOf': 'På vegne af',
			'NotMapped': 'ikke kortlagt',
			'Preview': 'Eksempel',
			'SignatureType': 'Underskrifttype',
			'EasySign': 'EasySign',
			'MitId': 'MitId',
			'SignatureTypeRequired': 'Underskrifttype kræves',
			'Notifications': 'Kvitteringsmails',
			'NotifiedEmails': 'E-mails uden vedhæftning',
			'NotifiedAttachmentEmails': 'E-mails med vedhæftning',
			'AddNotifiedEmailTooltip': 'Skriv en e-mail, og tryk på enter',
			'AddNotifiedEmailError': 'Indtast en gyldig e-mail',
			'AttachDocumentToEmail': 'Vedhæft dokument i email',
			'SendCompletedEmailToSigners': 'Send kvitteringsmail til underskriver',
			'AttachDocumentToSignerEmail': 'Vedhæft dokument i kvitteringsmail',
			'AttachmentsExceedingThreshold': 'Bemærk, at hvis formularen har et eller flere filoverførselsfelt, og størrelsen på de uploadede filer er for stor, sendes den ikke med e-mailen',
		},
		'EditField': {
			'Label': 'Etiket',
			'LabelRequired': 'Etiket kræves',
			'Name': 'Navn',
			'Placeholder': 'Pladsholder',
			'NameRequired': 'Navn kræves',
			'NameNotUnique': 'Navnet er ikke unikt',
			'NoRestrictedCharacters': '/\. "$*<>:|? ikke tilladt',
			'Value': 'Værdi',
			'ValueRequired': 'Værdi krævet',
			'Options': 'Indstillinger',
			'OptionsRequired': 'Indstillinger kræves',
			'EnableAutoComplete': 'aktivere autofuldførelse',
			'Required': 'krævet',
			'DependenciesMet': 'Alle afhængigheder skal være opfyldt',
			'Multi': 'Multi',
			'MinValue': 'Minimumsværdi',
			'MaxValue': 'Maksimumsværdi',
			'MaxMinError': 'Maksimumsværdi skal være større end minimumsværdi',
			'Step': 'Trin',
			'DateFormat': 'Datoformat',
			'DependencyList': 'Afhængighedsliste',
			'CustomValue': 'Tilpasset værdi',
			'Exact': 'Præcis',
			'LessThan': 'Mindre end',
			'MoreThan': 'Mere end',
			'ChooseValue': 'Vælg en værdi',
			'ValueNotUnique': 'Værdi er ikke unik',
			'MatchedValue': 'Matchet værdi',
			'DependentFields': 'Afhængige felter',
			'Update': 'Opdatering',
			'HasValue': 'Har værdi',
			'NoValue': 'Ingen værdi',
			'Delete': 'Slet',
			'OptionsTooltip': 'Skriv Indstillinger, og tryk på enter',
			'AddOptionWithDescriptionTooltip': 'Tilføj mulighed',
			'Success': 'Succes',
			'Failure': 'Fiasko',
			'UploadViaExcel': 'Upload via Excel',
			'DownloadExcelTemplate': 'Download Excel-skabelon',
			'EditOption': {
				'AddOptionWithDescription': 'Tilføj indstilling',
				'EditOptionWithDescription': 'Rediger indstilling',
				'Title': 'Titel',
				'Value': 'Værdi',
				'Description': 'Beskrivelse',
				'OptionTitleRequired': 'indstilling titel krævet',
				'OptionValueRequired': 'indstilling værdi krævet',
				'OptionDescriptionRequired': 'indstilling beskrivelse krævet',
				'Save': 'Gem',
				'Cancel': 'Annuller',
			}
		},
		'TSvariables': {
			'Formsaved': 'Blanket gemt',
			'FormPublished': 'Blanket blev publiceret',
			'FormUnpublished': 'Blanket blev upubliceret',
			'FormImported': 'Blanket importeret',
			'FormExported': 'Blanket eksporteret',
			'ImportFormError': 'Fejl ved import af blanket',
			'InvalidFileFormat': 'Ugyldigt filformat',
			'InvalidJsonFileFormat': 'Ugyldigt JSON-filformat',
			'AnErrorOccurred': 'En fejl opstod',
			'FormAdded': 'Blanket tilføjet',
			'ValidCredentials': 'Gyldige legitimationsoplysninger',
			'OK': 'OK',
			'ConfirmDelete': 'Er du sikker på, at du vil slette',
			'UnsavedChangesConfirmMessage': 'Du har ikke gemte ændringer. Er du sikker på, at du vil forlade denne side?',
		}
	}
};
